import React, { useState, useEffect, useContext } from 'react';
import { Box, Typography } from '@mui/material';
import { Button } from 'components/button';
import { OnboardingContext } from '../context/onboardingContext';
import { theme } from 'themes/theme';
import { useAppSelector } from 'services/hook';

const EmailVerification = () => {
  const { goToStep } = useContext(OnboardingContext);
  const [counter, setCounter] = useState(60);
  const [isDisabled, setIsDisabled] = useState(true);

  const { user } = useAppSelector(state => state.register);

  useEffect(() => {
    if (counter > 0) {
      const timer = setInterval(() => {
        setCounter(prev => prev - 1);
      }, 1000);

      return () => clearInterval(timer);
    } else {
      setIsDisabled(false);
    }
  }, [counter]);

  const formatTime = (time: number) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
  };

  const handleResend = () => {
    setCounter(60);
    setIsDisabled(true);
  };

  return (
    <Box
      sx={{
        width: '34rem',
        margin: '0 auto',
        padding: '2.5rem',
        pt: '2rem'
      }}
    >
      <Box sx={{ width: { lg: '29.125rem' } }}>
        <Box
          width="inherit"
          sx={{
            mb: '2.5rem',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center'
          }}
        >
          <Typography
            variant="h6Bold"
            sx={{
              color: `${theme.palette.common.heading}`,
              mb: '0.5rem'
            }}
          >
            Verify your email
          </Typography>
          <Typography
            variant="bodyMediumRegular"
            sx={{
              color: `${theme.palette.common.labelColor}`
            }}
          >
            We sent an email to <strong>{user.email}</strong>. Check your inbox
            or spam folder and follow the instructions to proceed.
          </Typography>
        </Box>

        <Box width="inherit" sx={{ mb: '0.75rem' }}>
          <Button
            color="primary"
            size="md"
            onClick={handleResend}
            disabled={isDisabled}
            text={`Resend Email ${isDisabled ? `${formatTime(counter)}` : ''}`}
            width="100%"
            styleOverrides={{ marginBottom: '0rem' }}
          />
        </Box>

        <Box width="inherit" sx={{ mb: '1.75rem' }}>
          <Button
            transparent
            color="primary"
            size="md"
            onClick={() => goToStep(2)}
            text="Do this Later"
            width="100%"
            styleOverrides={{ marginBottom: '0.25rem', border: '0px' }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default EmailVerification;

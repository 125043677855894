import React, { useContext } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Stack, Typography } from '@mui/material';
import Divider from 'components/common/divider';
import { FormInput } from 'components/form';
import { Resolver, SubmitHandler, useForm } from 'react-hook-form';
import { theme } from 'themes/theme';
import { OnboardingContext } from '../context/onboardingContext';
import { orgStepFormValidator5 } from '../validators/onboardingValidators';
import { Button } from 'components/button';
import ShowInfo from 'components/info/showInfo';
import { useAddMemberMutation } from 'services/api/organizationApi';

type FormValues = {
  email?: string;
  firstname?: string;
  lastname?: string;
};

const Invite = () => {
  const context = useContext(OnboardingContext);
  const { goToStep, data, changeBtn, setChangeBtn, organizationId } = context;
  const businessName = data.business_name;
  const [inviteMember] = useAddMemberMutation();

  const {
    handleSubmit,
    control,
    formState: { isValid, isDirty, isSubmitting },
    reset
  } = useForm<FormValues>({
    mode: 'onTouched',
    defaultValues: {
      email: data.email,
      firstname: '',
      lastname: ''
    },
    resolver: yupResolver(
      orgStepFormValidator5
    ) as unknown as Resolver<FormValues>
  });

  const onSubmit: SubmitHandler<FormValues> = formData => {
    if (formData.email && formData.email.length > 0) {
      inviteMember({
        data: {
          email: formData.email,
          firstname: formData.firstname,
          lastname: formData.lastname
        },
        organizationId: organizationId
      })
        .unwrap()
        .then(response => {
          ShowInfo({
            message: response
              ? 'Invite sent successfully!'
              : 'Please enter at least one email address.',
            subText: '',
            type: response ? 'success' : 'warning',
            componentType: 'toast',
            hideProgressBar: false
          });

          response && reset({ email: '', firstname: '', lastname: '' });
          response && setChangeBtn(true);
        })
        .catch(err => {
          ShowInfo({
            message: 'Failed to send invites. Please try again.',
            type: 'error',
            subText: err?.message,
            componentType: 'toast',
            hideProgressBar: false
          });
        });
    } else {
      ShowInfo({
        message: 'Please enter at least one email address.',
        subText: '',
        type: 'warning',
        componentType: 'toast',
        hideProgressBar: false
      });
    }
  };

  const handleInviteMoreClick = () => {
    reset({
      email: '',
      firstname: '',
      lastname: ''
    });
    setChangeBtn(false);
  };

  return (
    <Box sx={{ width: { lg: '29.25rem' } }}>
      <Box
        width="inherit"
        sx={{
          mb: '2rem',
          textAlign: 'center',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        <Typography
          variant="h6Bold"
          sx={{
            color: `${theme.palette.common.heading}`,
            mb: '0.5rem'
          }}
        >
          Invite to Organization
        </Typography>
        <Typography
          variant="bodyMediumRegular"
          sx={{
            color: `${theme.palette.common.labelColor}`
          }}
        >
          {isValid
            ? 'Give selected team members a seat in your organization. Make sure the emails are accurate.'
            : `Welcome to ${businessName} HQ. Invite team members and make sure the emails are accurate.`}
        </Typography>
      </Box>

      <Box width="inherit">
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <Stack
            width="100%"
            direction="row"
            justifyContent="space-between"
            gap="1.5rem"
          >
            <FormInput<FormValues>
              control={control}
              label="FIRST NAME"
              name="firstname"
            />

            <FormInput<FormValues>
              control={control}
              label="LAST NAME"
              name="lastname"
            />
          </Stack>

          <FormInput<FormValues> control={control} name="email" label="EMAIL" />

          {!changeBtn && (
            <Button
              color="primary"
              size="lg"
              text="Invite"
              styleOverrides={{
                padding: '0.75rem 1rem',
                width: '100%'
              }}
              submit={true}
              disabled={!isValid || !isDirty}
              loadingIcon={isSubmitting}
            />
          )}

          {changeBtn && (
            <Button
              color="primary"
              size="lg"
              text="Complete Profile"
              styleOverrides={{
                padding: '0.75rem 1rem',
                width: '100%'
              }}
              onClick={() => goToStep(4)}
              loadingIcon={isSubmitting}
            />
          )}
        </form>
      </Box>

      <Divider mt="1.5rem" fontWeight={400} />

      <Box width="inherit" sx={{ mt: '1.5rem' }}>
        <Button
          color="grey"
          transparent
          size="lg"
          text={changeBtn ? 'Invite more' : 'Skip this step'}
          styleOverrides={{
            padding: '0.75rem 1rem',
            width: '100%',
            border: '0px',
            background: 'transparent'
          }}
          onClick={() => {
            changeBtn ? handleInviteMoreClick() : goToStep(4);
          }}
        />
      </Box>
    </Box>
  );
};

export default Invite;

import React, { useContext, useEffect } from 'react';
import { Box, Typography, Stack, Avatar } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { Resolver, SubmitHandler, useForm } from 'react-hook-form';
import fileupload from 'assets/svg/fileupload.svg';
import ellipse from 'assets/svg/ellipse.svg';
import { ReactComponent as Truck } from 'assets/svg/truck.svg';
import { FormFileInput, FormInput, FormTelephoneInput } from 'components/form';
import { Button } from 'components/button';
import { SvgWrapper } from 'components/svg';
import FileView from 'components/common/fileView';
import { PickupRequestContext } from '../pickupRequestContext';
import { agentInformationFormValidator } from '../../validator/pickupValidators';
import { theme } from 'themes/theme';
import { shadowsTheme } from 'themes/shadows';

type FormValues = {
  agent_photo: File | null;
  name: string;
  email: string;
  phone_number: string;
};

const PickupRequestFormStep1 = () => {
  const {
    data,
    handleNextStep,
    setPickupRequestModalOpen,
    uploadedFile,
    setUploadedFile
  } = useContext(PickupRequestContext);

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors }
  } = useForm<FormValues>({
    mode: 'onTouched',
    defaultValues: {
      agent_photo: data.agent_photo,
      name: data.name,
      email: data.email,
      phone_number: data.phone_number
    },
    resolver: yupResolver(
      agentInformationFormValidator
    ) as unknown as Resolver<FormValues>
  });

  const watchAgentPhoto = watch('agent_photo');

  useEffect(() => {
    setUploadedFile(watchAgentPhoto);
  }, [watchAgentPhoto]);

  const onSubmit: SubmitHandler<FormValues> = data => {
    handleNextStep(data);
  };

  return (
    <>
      <Stack
        display="flex"
        direction="column"
        alignItems="flex-start"
        sx={{
          padding: '1.25rem 1.25rem 1rem 1.25rem',
          borderBottom: '.125rem solid #FCFCFD',
          backgroundColor: '#FCFCFD',
          borderRadius: '0.75rem 0.75rem 0rem 0rem'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '2.25rem',
            width: '2.25rem',
            borderRadius: '6.25rem',
            backgroundColor: '#F8FBFE',
            padding: '0.5rem',
            boxShadow: shadowsTheme.iconShadow,
            marginBottom: '1rem'
          }}
        >
          <SvgWrapper
            icon={Truck}
            width="1.25rem"
            height="1.25rem"
            styleOverrides={{
              opacity: 0.8,
              fill: '#C3D7FD'
            }}
          />
        </Box>
        <Stack
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'start',
            justifyContent: 'center'
          }}
        >
          <Typography variant="h7Semibold" color="#344054">
            Pick Up Request
          </Typography>
          <Typography variant="bodyMediumRegular" color="#667085">
            Schedule pickup of your products using your product receipt
          </Typography>
        </Stack>
      </Stack>

      <Box
        sx={{
          overflowY: 'auto'
        }}
      >
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            padding: '1.25rem',
            justifyContent: 'flex-end',
            alignItems: 'center',
            height: '4.5rem',
            borderRadius: '0.75rem',
            border: '.063rem solid #FCFCFD',
            backgroundColor: '#F8FBFE',
            mt: '1.75rem'
          }}
        >
          {uploadedFile ? (
            <FileView
              preview={uploadedFile}
              removeFile={() => setUploadedFile(null)}
            />
          ) : (
            <Stack
              width="inherit"
              justifyContent="space-between"
              direction="column"
              gap=".5rem"
              sx={{
                padding: '0.75rem 0.5rem'
              }}
            >
              <Stack
                width="inherit"
                justifyContent="space-between"
                direction="row"
                gap="1rem"
              >
                <Stack alignItems="center" direction="row" gap=".5rem">
                  <Avatar src={fileupload} />
                  <Stack direction="column">
                    <Typography
                      variant="bodyMediumSemibold"
                      sx={{
                        color: `${theme.palette.common.heading}`
                      }}
                    >
                      Upload pickup agent photo
                    </Typography>
                    <Stack
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        width: '10rem'
                      }}
                    >
                      <Typography
                        variant="bodyMediumRegular"
                        color={theme.palette.common.textColor}
                      >
                        JPG format
                      </Typography>
                      <Box component="img" src={ellipse} />
                      <Typography
                        variant="bodyMediumRegular"
                        color={theme.palette.common.textColor}
                      >
                        Max. 5MB
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>

                <FormFileInput<FormValues>
                  control={control}
                  name="agent_photo"
                  label="Upload"
                  setFile={(file: File | null) => {
                    file && setValue('agent_photo', file);
                  }}
                  supportedFiles="image/jpg"
                  fileInputButtonStyle={{
                    border: '0.0625rem solid #0D5EBA',
                    borderRadius: '.5rem',
                    color: `${theme.palette.common.white}`,
                    backgroundColor: '#0D5EBA',
                    padding: '.5rem .75rem',
                    width: '5rem',
                    height: '2.25rem',
                    fontWeight: 600,
                    lineHeight: '1.26rem',
                    letterSpacing: '-0.01313rem',
                    '&:hover': {
                      border: '0.0625rem solid #0D5EBA',
                      color: `${theme.palette.common.white}`,
                      backgroundColor: '#0D5EBA'
                    }
                  }}
                />
              </Stack>
              {errors && errors.agent_photo ? (
                <Typography variant="bodySmallRegular" color="red">
                  {`${errors.agent_photo.message}`}
                </Typography>
              ) : null}
            </Stack>
          )}
        </Box>

        <Box
          sx={{
            mt: '1rem',
            padding: '1.25rem'
          }}
        >
          <FormInput<FormValues>
            control={control}
            name="name"
            label="NAME OF PICKUP AGENT"
          />

          <FormTelephoneInput<FormValues>
            control={control}
            name="phone_number"
            label="PHONE NUMBER"
          />

          <FormInput<FormValues> control={control} name="email" label="EMAIL" />
        </Box>
      </Box>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            borderTop: '0.0625rem solid #F5F6F7',
            padding: '1.25rem 1.5rem',
            marginBottom: '0.25rem'
          }}
        >
          <Button
            color="grey"
            transparent
            size="lg"
            onClick={() => {
              setPickupRequestModalOpen(false);
            }}
            text="Back"
            width="48%"
          />
          <Button submit color="primary" size="lg" text="Next" width="48%" />
        </Box>
      </form>
    </>
  );
};

export default PickupRequestFormStep1;

import React, { useState, createContext } from 'react';
import Invite from 'pages/onboarding/steps/invite';
import SetupOrganization from 'pages/onboarding/steps/setupOrganization';
import GetStarted from '../steps/getStarted';
import EmailVerification from '../steps/emailVerification';
import LoadingWrapper from '../common/onboardingLoadingpage';
import SelectUser from '../steps/selectUser';
import ProductInformation from '../steps/productInformation';
import CompleteProfile from '../steps/completeProfile';
export type OnboardingData = {
  organizationId: string;
  organization_type: string;
  product: string[];
  otherProduct: string;
  file: File[] | null;
  logo: File | null;
  business_name: string;
  street_address: string;
  postal_code: string;
  state: string;
  industry: string;
  locality: string;
  country: string;
  administrative_area: string;
  email: string;
  firstname: string;
  lastname: string;
};

const initialValues: OnboardingData = {
  organization_type: '',
  product: [],
  otherProduct: '',
  file: null,
  logo: null,
  business_name: '',
  street_address: '',
  postal_code: '',
  state: '',
  industry: '',
  locality: '',
  country: '',
  administrative_area: '',
  email: '',
  firstname: '',
  lastname: '',
  organizationId: ''
};

export interface OnboardingContextType {
  data: OnboardingData;
  setData: React.Dispatch<React.SetStateAction<OnboardingData>>;
  currentStep: number;
  uploadedFile: File[] | null;
  setUploadedFile: React.Dispatch<React.SetStateAction<File[] | null>>;
  preview: File | null;
  setPreview: React.Dispatch<React.SetStateAction<File | null>>;
  showHighLight: string[];
  setShowHighLight: React.Dispatch<React.SetStateAction<string[]>>;
  changeBtn: boolean;
  setChangeBtn: React.Dispatch<React.SetStateAction<boolean>>;
  steps: { element: JSX.Element }[];
  handleNextStep: (newData: Partial<OnboardingData>) => void;
  handlePrevStep: () => void;
  goToStep: (index: number) => void;
  organizationId: string;
  setOrganizationId: React.Dispatch<React.SetStateAction<string>>;
  businessName: string;
  setBusinessName: React.Dispatch<React.SetStateAction<string>>;
}

export const OnboardingContextDefaults: OnboardingContextType = {
  data: initialValues,
  setData: () => undefined,
  currentStep: 0,
  uploadedFile: null,
  setUploadedFile: () => undefined,
  preview: null,
  setPreview: () => undefined,
  showHighLight: [],
  setShowHighLight: () => undefined,
  changeBtn: false,
  setChangeBtn: () => undefined,
  steps: [],
  handleNextStep: () => undefined,
  handlePrevStep: () => undefined,
  goToStep: () => undefined,
  organizationId: '',
  setOrganizationId: () => undefined,
  businessName: '',
  setBusinessName: () => undefined
};

export const OnboardingContext = createContext<OnboardingContextType>(
  OnboardingContextDefaults
);

const OnboardingContextWrapper: React.FunctionComponent<
  React.PropsWithChildren
> = ({ children }) => {
  const [data, setData] = useState<OnboardingData>(initialValues);
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [uploadedFile, setUploadedFile] = useState<null | File[]>(null);
  const [preview, setPreview] = useState<File | null>(null);
  const [showHighLight, setShowHighLight] = useState<string[]>([]);
  const [changeBtn, setChangeBtn] = useState(false);
  const [organizationId, setOrganizationId] = useState('');
  const [businessName, setBusinessName] = useState<string>('');

  const steps = [
    { element: <GetStarted /> },
    { element: <EmailVerification /> },
    { element: <SetupOrganization /> },
    { element: <Invite /> },
    { element: <LoadingWrapper isLoading={true} /> },
    { element: <CompleteProfile /> },
    { element: <SelectUser /> },
    { element: <ProductInformation /> }
  ];

  const handleNextStep = (newData: Partial<OnboardingData>) => {
    setData(prev => ({ ...prev, ...newData }));
    setCurrentStep(prev => Math.min(prev + 1, steps.length));
  };

  const handlePrevStep = () => {
    setCurrentStep(prev => Math.max(prev - 1, 0));
  };

  const goToStep = (index: number) => {
    if (index >= 0 && index <= steps.length) {
      setCurrentStep(index);
    }
  };

  const values: OnboardingContextType = {
    data,
    setData,
    currentStep,
    uploadedFile,
    setUploadedFile,
    preview,
    setPreview,
    showHighLight,
    setShowHighLight,
    changeBtn,
    setChangeBtn,
    steps,
    handleNextStep,
    handlePrevStep,
    goToStep,
    organizationId,
    setOrganizationId,
    businessName,
    setBusinessName
  };

  return (
    <OnboardingContext.Provider value={values}>
      {children}
    </OnboardingContext.Provider>
  );
};

export default OnboardingContextWrapper;

import { nameCheck } from 'utilities/helperFunc/nameCheck';
import * as Yup from 'yup';

export const orgStepFormValidator1 = Yup.object({
  organization_type: Yup.string().required('This field is required')
});

export const orgStepFormValidator2 = Yup.object({
  product: Yup.array()
    .min(1, 'Select at least one category')
    .of(Yup.string())
    .max(4)
    .required('This field is required'),
  otherProduct: Yup.string().when('product', {
    is: (product: string[]) => product.includes('other'),
    then: schema => schema.required('This field is required')
  })
});

export const orgStepFormValidator3 = Yup.object({
  file: Yup.mixed<FileList>()
    .nullable()
    .notRequired()
    .test('fileType', 'Only PDF files are allowed', value => {
      if (!value || !value[0]) return true;
      return value[0].type === 'application/pdf';
    })
    .test('fileSize', 'File size is too large', value => {
      if (!value || !value[0]) return true;
      const maxSize = 160 * 1024;
      return value[0].size <= maxSize;
    })
});

export const orgStepFormValidator4 = Yup.object({
  logo: Yup.mixed<FileList>()
    .nullable()
    .notRequired()
    .test('fileType', 'Only PDF files are allowed', value => {
      if (!value || !value[0]) return true;
      return value[0].type === 'application/pdf';
    })
    .test('fileSize', 'File size is too large', value => {
      if (!value || !value[0]) return true;
      const maxSize = 5 * 1024 * 1024; // 5 MB in bytes
      return value[0].size <= maxSize;
    }),
  business_name: Yup.string().required('This field is required'),
  street_address: Yup.string().required('This field is required'),
  postal_code: Yup.string().required('This field is required'),
  state: Yup.string().required('This field is required'),
  industry: Yup.string().required('This field is required'),
  locality: Yup.string().required('This field is required')
});

export const orgStepFormValidator5 = Yup.object({
  firstname: Yup.string()
    .required('First name is a required field')
    .test(
      'isValidPass',
      'First name should not contain any number or special character',
      (value: string) => nameCheck(value)
    ),
  lastname: Yup.string()
    .required('Last name is a required field')
    .test(
      'isValidPass',
      'First name should not contain any number or special character',
      (value: string) => nameCheck(value)
    ),
  email: Yup.string()
    .required('Email is a required field')
    .email('Email must be a valid email')
});

import React, { useContext, useState } from 'react';
import { ReactComponent as Merchant } from 'assets/svg/merchants.svg';
import { ReactComponent as Bank } from 'assets/svg/bank.svg';
import { ReactComponent as Truck } from 'assets/svg/truck.svg';
import { Button } from 'components/button';
import { theme } from 'themes/theme';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Typography } from '@mui/material';
import { useForm, Resolver, SubmitHandler } from 'react-hook-form';
import { OnboardingContext } from '../context/onboardingContext';
import { orgStepFormValidator1 } from '../validators/onboardingValidators';
import { FormRadio } from 'components/form';
import { useActivateSupplyChainProfileMutation } from 'services/api/supplyChainApi';
import ShowInfo from 'components/info/showInfo';

type FormValues = {
  organization_type: string;
};

const SelectUser = () => {
  const context = useContext(OnboardingContext);
  const { handleNextStep, data, setData, organizationId } = context;
  const [isLoading, setIsLoading] = useState(false);

  const [activateSupplyChainProfile] = useActivateSupplyChainProfileMutation();

  const {
    handleSubmit,
    control,
    formState: { isValid }
  } = useForm<FormValues>({
    mode: 'onTouched',
    defaultValues: {
      organization_type: data.organization_type
    },
    resolver: yupResolver(
      orgStepFormValidator1
    ) as unknown as Resolver<FormValues>
  });

  const onSubmit: SubmitHandler<FormValues> = async formData => {
    setIsLoading(true);

    setData(prev => ({
      ...prev,
      organization_type: formData.organization_type
    }));

    try {
      const response = await activateSupplyChainProfile({
        organizationId: organizationId,
        body: {
          organization_type: formData.organization_type
        }
      });

      if ('data' in response) {
        if (response.data?.success === false) {
          response.data?.message || 'Failed to select user type';
        }

        ShowInfo({
          message: ' A supplychain profile was selected,',
          type: 'success',
          componentType: 'toast'
        });

        handleNextStep(formData);
      } else if ('error' in response) {
        ShowInfo({
          message:
            'Failed to select user type. Please Verify your email then try again',
          type: 'error',
          componentType: 'toast'
        });
      }
    } catch (error) {
      ShowInfo({
        message: 'Failed to select user type. Please try again.',
        type: 'error',
        componentType: 'toast'
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box sx={{ width: { lg: '29.25rem' } }}>
      <Box width="inherit" sx={{ mb: '2rem', mt: '5rem', textAlign: 'center' }}>
        <Typography
          variant="h5"
          sx={{
            color: `${theme.palette.common.heading}`
          }}
        >
          Select User Type
        </Typography>
        <Typography
          variant="bodyMediumRegular"
          sx={{
            color: `${theme.palette.common.labelColor}`
          }}
        >
          Which best describes you?
        </Typography>
      </Box>

      <Box width="inherit">
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <FormRadio<FormValues>
            control={control}
            name="organization_type"
            label=""
            radioData={radioData}
          />

          <Button
            submit
            color="primary"
            size="lg"
            disabled={!isValid || isLoading}
            text="Next"
            width="100%"
            styleOverrides={{ marginBottom: '0.25rem' }}
          />
        </form>
      </Box>
    </Box>
  );
};

export default SelectUser;

const radioData = [
  {
    image: Merchant,
    heading: 'Merchants',
    value: 'merchant',
    subHeading: 'Create, issue and manage Product receipts'
  },
  {
    image: Bank,
    heading: 'Lenders',
    value: 'lender',
    subHeading:
      'Secure and finance Product receipts on behalf for your clients/customers'
  },
  {
    image: Truck,
    heading: 'Distributors',
    value: 'distributor',
    subHeading: 'Source Product receipts for Wholesale or Retail Distribution'
  }
];
